@font-face {
    font-family: 'Clear Sans';
    src: url('ClearSans-Italic.eot');
    src: local('Clear Sans Italic'), local('ClearSans-Italic'),
        url('ClearSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('ClearSans-Italic.woff2') format('woff2'),
        url('ClearSans-Italic.woff') format('woff'),
        url('ClearSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Clear Sans';
    src: url('ClearSans-Medium.eot');
    src: local('Clear Sans Medium'), local('ClearSans-Medium'),
        url('ClearSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('ClearSans-Medium.woff2') format('woff2'),
        url('ClearSans-Medium.woff') format('woff'),
        url('ClearSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: url('ClearSans.eot');
    src: local('Clear Sans'), local('ClearSans'),
        url('ClearSans.eot?#iefix') format('embedded-opentype'),
        url('ClearSans.woff2') format('woff2'),
        url('ClearSans.woff') format('woff'),
        url('ClearSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: url('ClearSans-Bold.eot');
    src: local('Clear Sans Bold'), local('ClearSans-Bold'),
        url('ClearSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('ClearSans-Bold.woff2') format('woff2'),
        url('ClearSans-Bold.woff') format('woff'),
        url('ClearSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: url('ClearSans-BoldItalic.eot');
    src: local('Clear Sans Bold Italic'), local('ClearSans-BoldItalic'),
        url('ClearSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('ClearSans-BoldItalic.woff2') format('woff2'),
        url('ClearSans-BoldItalic.woff') format('woff'),
        url('ClearSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Clear Sans';
    src: url('ClearSans-MediumItalic.eot');
    src: local('Clear Sans Medium Italic'), local('ClearSans-MediumItalic'),
        url('ClearSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('ClearSans-MediumItalic.woff2') format('woff2'),
        url('ClearSans-MediumItalic.woff') format('woff'),
        url('ClearSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Clear Sans';
    src: url('ClearSans-Thin.eot');
    src: local('Clear Sans Thin'), local('ClearSans-Thin'),
        url('ClearSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('ClearSans-Thin.woff2') format('woff2'),
        url('ClearSans-Thin.woff') format('woff'),
        url('ClearSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: url('ClearSans-Light.eot');
    src: local('Clear Sans Light'), local('ClearSans-Light'),
        url('ClearSans-Light.eot?#iefix') format('embedded-opentype'),
        url('ClearSans-Light.woff2') format('woff2'),
        url('ClearSans-Light.woff') format('woff'),
        url('ClearSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

